/* eslint-disable camelcase */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { injectAuthorizationToken, refreshToken } from './initAxios';

const qlogPreprocessingServiceBaseUrl = process.env.NEXT_PUBLIC_QLOG_PREPROCESSING_SERVICE_URL;
const client = axios.create({
  baseURL: qlogPreprocessingServiceBaseUrl
});

client.interceptors.request.use(injectAuthorizationToken);
createAuthRefreshInterceptor(client, refreshToken);

export default client;
