import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { deleteResource } from 'store/modules/resources';
import { useStyles } from './ResourceUploader.styles';
import RefreshAlertDialog from './RefreshAlert';

export const ResourceUploaderPropTypes = {
  uploads: PropTypes.object.isRequired
};

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" color="secondary" size={30} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function ResourceItem({ title, percentage, ready }) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" width="100%" marginBottom={1} height={30}>
      <Typography className={classes.flex}>{title}</Typography>
      {!ready && <CircularProgressWithLabel value={percentage} />}
      {ready && <CheckCircleOutlineIcon className={classes.icon} />}
    </Box>
  );
}

function ResourceUploader({ uploads }) {
  const classes = useStyles();
  const currentUploads = uploads || {};
  const keys = Object.keys(currentUploads);
  const activeKeys = keys.filter((key) => !currentUploads[key].ready);
  const [alertOpen, setAlertOpen] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const preventRefresh = function (e) {
    if (activeKeys.length) {
      (e || window.event).preventDefault();
      setAlertOpen(true);
      (e || window.event).returnValue = '';

      return '';
    }
  };
  const onAlertConfirm = async (e) => {
    await dispatch(deleteResource({ selectedFiles: activeKeys }));
    router.reload(window.location.pathname);
  };
  useEffect(() => {
    document.onkeydown = function (e) {
      if (
        e.keyCode === 116 ||
        (e.ctrlKey && e.keyCode == 82) ||
        (window?.event?.ctrlKey && window?.event?.keycode == 82)
      ) {
        if (activeKeys.length) {
          setAlertOpen(true);
          return false;
        }
      }
    };
    window.addEventListener('beforeunload', preventRefresh);

    return () => {
      window.removeEventListener('beforeunload', preventRefresh);
      document.onkeydown = null;
    };
  }, [activeKeys]);

  return (
    <>
      <Snackbar
        data-testid="ResourceUploader"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={activeKeys.length > 0}
        ContentProps={{ classes: { message: classes.content } }}
        message={
          <Box>
            <Typography variant="h6" className={classes.title}>
              Uploading Resources:
            </Typography>
            {keys &&
              keys.map((key) => (
                <ResourceItem
                  key={key}
                  title={uploads[key].name}
                  percentage={uploads[key].percentage}
                  ready={uploads[key].ready}
                />
              ))}
          </Box>
        }
      />
      {alertOpen && (
        <RefreshAlertDialog
          onClose={(e) => setAlertOpen(false)}
          onCancel={(e) => setAlertOpen(false)}
          onConfirm={onAlertConfirm}
          open={alertOpen}
        />
      )}
    </>
  );
}

ResourceUploader.propTypes = ResourceUploaderPropTypes;

export default ResourceUploader;
