import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%'
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  flex: {
    flex: 1
  },
  icon: {
    height: 30,
    width: 30,
    color: theme.palette.primary.main
  }
}));

export { useStyles };
