import * as types from './wizard.types';

export const initialState = {
  inputs: [],
  uploading: {},
  outputs: [],
  loading: false,
  error: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.LIST_INPUTS_REQUEST:
    case types.UPDATE_INPUTS_REQUEST:
    case types.LIST_OUTPUTS_REQUEST:
    case types.PROCESS_WELLS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case types.UPLOAD_FILES_REQUEST: {
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [payload.name]: { name: payload.name, percentage: 0 }
        }
      };
    }
    case types.UPLOAD_UPDATE_STATUS: {
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [payload.name]: { name: payload.name, percentage: payload.percentage }
        }
      };
    }
    case types.LIST_INPUTS_SUCCESS:
    case types.UPDATE_INPUTS_SUCCESS: {
      return {
        ...state,
        inputs: payload.inputs,
        loading: false
      };
    }
    case types.LIST_OUTPUTS_SUCCESS: {
      return {
        ...state,
        outputs: payload.outputs
      };
    }
    case types.UPLOAD_FILES_SUCCESS: {
      const file = state.uploading[payload.uploadingKey];
      file.ready = true;

      return {
        ...state,
        uploading: { ...state.uploading, [payload.uploadingKey]: file },
        inputs: [...state.inputs, payload.uploaded]
      };
    }
    case types.LIST_INPUTS_FAILURE:
    case types.UPDATE_INPUTS_FAILURE:
    case types.UPLOAD_FILES_FAILURE:
    case types.LIST_OUTPUTS_FAILURE:
    case types.PROCESS_WELLS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    }
    default:
      return state;
  }
}
