/* eslint-disable camelcase */
import axios from 'axios';
import * as types from './wizard.types';
import client from 'utils/qlogClient';

export const listInputs = () => {
  return async (dispatch) => {
    dispatch({
      type: types.LIST_INPUTS_REQUEST
    });
    try {
      const { data = {} } = await client.get('/jobs/inputs');

      dispatch({
        type: types.LIST_INPUTS_SUCCESS,
        payload: {
          inputs: data.inputs
        }
      });
    } catch (err) {
      return dispatch({
        type: types.LIST_INPUTS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const updateFiles = ({ input_id, configuration, well, deleted }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_INPUTS_REQUEST
    });

    const { inputs } = getState().wizard;

    try {
      const { data = {} } = await client.post(`/jobs/inputs/${input_id}`, {
        configuration,
        well,
        deleted
      });

      const { input: updated = {} } = data;
      const updatedFiles = inputs.map((input) => {
        if (input.id !== input_id) {
          return input;
        }
        return updated;
      });

      return dispatch({
        type: types.UPDATE_INPUTS_SUCCESS,
        payload: {
          inputs: updatedFiles
        }
      });
    } catch (err) {
      return dispatch({
        type: types.UPDATE_INPUTS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

const updateProgress = (dispatch, filename) => (progressEvent) => {
  var percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;
  dispatch({
    type: types.UPLOAD_UPDATE_STATUS,
    payload: {
      name: filename,
      percentage: percentCompleted
    }
  });
};

export const uploadFiles = ({ files }) => {
  return async (dispatch) => {
    try {
      for (const file of files) {
        const { data = {} } = await client.post('/jobs/inputs', {
          configuration: {
            filename: file.path
          },
          filename: file.path,
          well: 'wellname'
        });
        const { upload_url } = data;

        dispatch({
          type: types.UPLOAD_FILES_REQUEST,
          payload: {
            name: file.path
          }
        });

        axios
          .put(upload_url, file, {
            headers: {
              'x-amz-acl': 'private',
              'content-type': 'application/x-www-form-urlencoded'
            },
            onUploadProgress: updateProgress(dispatch, file.path)
          })
          .then((res) =>
            dispatch({
              type: types.UPLOAD_FILES_SUCCESS,
              payload: {
                uploaded: data.input,
                uploadingKey: file.path
              }
            })
          )
          .catch((err) => {
            console.log(err);
            return dispatch({
              type: types.UPLOAD_FILES_FAILURE,
              payload: {
                error: err.response.data.error || err.message
              }
            });
          });
      }
    } catch (err) {
      return dispatch({
        type: types.UPLOAD_FILES_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const processWells = ({ inputs }) => {
  return async (dispatch) => {
    dispatch({
      type: types.PROCESS_WELLS_REQUEST
    });

    try {
      const { data = {} } = await client.post('wells/process', inputs);

      return dispatch({
        type: types.PROCESS_WELLS_SUCCESS,
        payload: {
          data
        }
      });
    } catch (err) {
      console.log(err);
      return dispatch({
        type: types.PROCESS_WELLS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const listOutputs = () => {
  return async (dispatch) => {
    dispatch({
      type: types.LIST_OUTPUTS_REQUEST
    });
    try {
      const { data = {} } = await client.get('/jobs');

      dispatch({
        type: types.LIST_OUTPUTS_SUCCESS,
        payload: {
          outputs: data
        }
      });
    } catch (err) {
      console.log(err);
      return dispatch({
        type: types.LIST_OUTPUTS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
