import React from 'react';
import Dialog from 'components/Common/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function RefreshAlertDialog({ open, onClose, onCancel, onConfirm }) {
  return (
    <div>
      <Dialog
        open={!!open}
        fullWidth
        onClose={onClose}
        onConfirm={onConfirm}
        onCancel={onCancel}
        aria-labelledby="refresh-alert-dialog-title"
        aria-describedby="refresh-alert-dialog-description"
      >
        <DialogTitle id="refresh-alert-dialog-title">Are you sure?</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There are
            <strong> files being uploaded</strong>, refreshing the page will break the upload process.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
