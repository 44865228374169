const prefix = '@qes/wizard';

export const LIST_INPUTS_REQUEST = `${prefix}/LIST_INPUTS_REQUEST`;
export const LIST_INPUTS_SUCCESS = `${prefix}/LIST_INPUTS_SUCCESS`;
export const LIST_INPUTS_FAILURE = `${prefix}/LIST_INPUTS_FAILURE`;

export const UPDATE_INPUTS_REQUEST = `${prefix}/UPDATE_INPUTS_REQUEST`;
export const UPDATE_INPUTS_SUCCESS = `${prefix}/UPDATE_INPUTS_SUCCESS`;
export const UPDATE_INPUTS_FAILURE = `${prefix}/UPDATE_INPUTS_FAILURE`;

export const UPLOAD_FILES_REQUEST = `${prefix}/UPLOAD_FILES_REQUEST`;
export const UPLOAD_FILES_SUCCESS = `${prefix}/UPLOAD_FILES_SUCCESS`;
export const UPLOAD_FILES_FAILURE = `${prefix}/UPLOAD_FILES_FAILURE`;
export const UPLOAD_UPDATE_STATUS = `${prefix}/UPLOAD_FILES_STATUS`;

export const PROCESS_WELLS_REQUEST = `${prefix}/PROCESS_WELLS_REQUEST`;
export const PROCESS_WELLS_SUCCESS = `${prefix}/PROCESS_WELLS_SUCCESS`;
export const PROCESS_WELLS_FAILURE = `${prefix}/PROCESS_WELLS_FAILURE`;

export const LIST_OUTPUTS_REQUEST = `${prefix}/LIST_OUTPUTS_REQUEST`;
export const LIST_OUTPUTS_SUCCESS = `${prefix}/LIST_OUTPUTS_SUCCESS`;
export const LIST_OUTPUTS_FAILURE = `${prefix}/LIST_OUTPUTS_FAILURE`;
